import { createResource, createSignal, on, onCleanup, onMount, Show } from "solid-js";

import { ProjectView } from "./Project";

import Admin from "./Admin";
import { AdminContextProvider } from "./AdminContext";

import socket, { SocketStatus } from "./socket";

export default function App() {

  const [state, setState] = createSignal({
    username: '', 
    role: '', 
    project: '',
    creatingProject: false,
    gitlab_not_ready: false
  });

  const [log, setLog] = createSignal('');

  onMount(() => {
    console.log('Mounted App');
    socket.on("authenticated", ({username, role}) => {

        console.log({username, role});
        setState({...state(), username, role});
    
    });
    socket.on("gitlab-not-ready", () => {
      console.log('gitlab-not-ready');
      setState({...state(), gitlab_not_ready: true});
    });
    socket.on("gitlab-ready", () => {
      console.log('gitlab-ready');
      setState({...state(), gitlab_not_ready: false});
    });
    socket.on('project', (project) => {
      console.log(project);
      setState({...state(), project, creatingProject: false});
    })
    socket.on('project-log', (_log) => {
      console.log(_log);
      setLog(log()+'\n'+_log);
    });

  });

  function createProject(data, event) {
    socket.emit('create-project');
    setState({...state(), creatingProject: true});
  }

  const [accessTokenInput, setAccessTokenInput] = createSignal('');
  function onAccessTokenChange(event) {
    console.log(event.target.value);
    setAccessTokenInput(event.target.value);
  }
  function submitAccessToken(event) {
    socket.emit('access-token', accessTokenInput());
  }

  return (
    <>
      <h1>{state().username || 'Non connecté'}</h1>
      <Show when={!state().username}>
      <button type="button" class="btn btn-primary"
        onClick={() => {
          location.href = '/login';
        }}>
          Se connecter
        </button>
      </Show>
      <Show when={state().gitlab_not_ready}>
        <Show when={state().role == 'student'}>
          <div>Veuillez contacter l'enseignant.</div>
        </Show>
        <Show when={state().role == 'admin'}>
          <div>Access Token: <input type="text" value={accessTokenInput()} onInput={onAccessTokenChange} /> <button onClick={submitAccessToken}>Submit</button></div>
        </Show>
      </Show>
      <Show when={state().project}>
        <ProjectView state={state} createProject={createProject} />
      </Show>
      <Show when={state().role === 'admin' && !state().gitlab_not_ready}>
        <AdminContextProvider>
          <Admin />
        </AdminContextProvider>
      </Show>
      <br/>
      <pre>
        {log()}
      </pre>
      <SocketStatus />
    </>
  );
}