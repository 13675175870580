import { createResource, createSignal, createMemo, onCleanup, onMount, Show, For } from "solid-js";

import { useAdminContext } from "./AdminContext";

function resultsToScore(results, options={}) {
    let score = -1;
    for(const r of results) {
        if(options.verified && r.date === 'null') continue;

        let {exit_code, note} = r;
        note = parseInt(note);
        if(exit_code == 0) {
            score = 100;
            break;
        }
        if(note > score)
            score = note;
    }

    if(score == 100) return '✓';
    if(score > 0) return score;
    
    return '❌'
};

const User = (props) => {
    return <a href={props.user.project_url} target="_blank">{props.user.username}</a>
}

export default () => {
    
    
    const [adminStore, setAdminStore] = useAdminContext();

    const filtered = createMemo(
        () => {
            const currentYear = new Date().getFullYear();
            const results = adminStore.results;
            const verifiedProblems = {};
            const problems = {};
            const users = adminStore.users.filter(({username}) => results[username]);
            
            Object.keys(results).forEach((username) => {
                results[username].forEach((p) => {
                    const problem_name = p.problem_name || p.folder;
                    if(p.verified_problem_name !== "null" && p.deadline.localeCompare(currentYear) >= 0)
                    {
                        if(!verifiedProblems[problem_name]) verifiedProblems[problem_name] = {};
                        verifiedProblems[problem_name][username] = p.results || null;
                    } else {
                        if(!problems[problem_name]) problems[problem_name] = {};
                        problems[problem_name][username] = p.results || null;
                    }
                });
           });
           console.log({verifiedProblems, problems, users})
           return {verifiedProblems, problems, users};
        }
    );

    return <div>
    <h3>Problèmes vérifiés</h3>
    <div class="problems-table-wrapper">
        <table class="table table-bordered table-hove table-sm rotate problems">
        <thead>
            <tr class="rotate">
            <th scope="col" class="student-col" style={{zIndex:100001}}>Etudiant</th>{
                Object.keys(filtered().verifiedProblems).sort().map((problem_name, i) => (
                <th scope="col" class="rotate"  style={{zIndex:100000-i}}><div><span>{problem_name}</span></div></th>
                ))
            }
            </tr>
        </thead>
        <tbody>
            <For each={filtered().users.sort((a,b) => a.username.localeCompare(b.username))} fallback={<div>Loading...</div>}>
                {(item, index) => (
                    <tr key={item.username}>
                        <th scope="row" class={"student-col status-"+item.status}>
                            <User user={item} />
                        </th>
                        <For each={Object.keys(filtered().verifiedProblems).sort()}>
                            {(problem_name) => (
                                <td>{
        filtered().verifiedProblems[problem_name][item.username] 
     ? resultsToScore(filtered().verifiedProblems[problem_name][item.username], {verified: true}) 
     : ' '}
                                </td>
                            )}
                        </For>
                    </tr>
                )}
            </For>
        </tbody>
        </table>
    </div>

    
    <h3>Autres problèmes</h3>
    <div class="problems-table-wrapper">
        <table class="table table-bordered table-hove table-sm rotate problems">
        <thead>
            <tr class="rotate">
            <th scope="col" class="student-col" style={{zIndex:100001}}>Etudiant</th>{
                Object.keys(filtered().problems).sort().map((problem_name, i) => (
                <th scope="col" class="rotate"  style={{zIndex:100000-i}}><div><span>{problem_name}</span></div></th>
                ))
            }
            </tr>
        </thead>
        <tbody>
            <For each={filtered().users.sort((a,b) => a.username.localeCompare(b.username))} fallback={<div>Loading...</div>}>
                {(item, index) => (
                    <tr key={item.username}>
                        <th scope="row" class={"student-col status-"+item.status}>
                            <User user={item} />
                        </th>
                        <For each={Object.keys(filtered().problems).sort()}>
                            {(problem_name) => (
                                <td>{
        filtered().problems[problem_name][item.username] 
     ? resultsToScore(filtered().problems[problem_name][item.username]) 
     : ' '
     }
                                </td>
                            )}
                        </For>
                    </tr>
                )}
            </For>
        </tbody>
        </table>
    </div>
</div>
}

/*
  <tbody>
    {filtered().users.sort((a,b) => a.username.localeCompare(b.username)).filter(a => a.username != 'bramas' && a.username != 'dufresnee').map(({status, username, results}) => (
                  <tr key={username}>
                    <th scope="row" class={"student-col status-"+status}>{username} <a href={GITLAB_REPO_URL.replace('{namespace}', state.project.namespace.path).replace('{name}', username.replace('.','-'))} target="_blank">r</a></th>
                    {
                      Object.keys(filtered().verifiedProblems).sort().map((problem_name) => (
                        <td>{(filtered().verifiedProblems[problem_name][username] 
                          && parseInt(filtered().verifiedProblems[problem_name][username])) || '0'}</td>
                      ))
                    }
                  </tr>)
    )}
  </tbody>
</table>
</div>
<h3>Autres problèmes</h3>
<div class="problems-table-wrapper">
<table class="table table-bordered table-hove table-sm rotate problems">
  <thead>
    <tr class="rotate">
      <th scope="col" class="student-col" style={{zIndex:100001}}>Etudiant</th>{
        Object.keys(filtered().problems).sort().map((problem_name, i) => (
          <th scope="col" class="rotate" style={{zIndex:100000-i}}><div><span>{problem_name}</span></div></th>
        ))
      }
    </tr>
  </thead>
  <tbody>
    {filtered().users.map(({status, username, results}) => (
                  <tr key={username}>
                    <th scope="row" class={"student-col status-"+status}>{username}</th>
                    {
                      Object.keys(filtered().problems).sort().map((problem_name) => (
                        <td>{filtered().problems[problem_name][username] === undefined
                        ? '' :
                        ( parseInt(filtered().problems[problem_name][username])
                          ?'✓':'❌'
                        )}</td>
                      ))
                    }
                  </tr>)
    )}
  </tbody>
</table>
</div>
</div>
}*/