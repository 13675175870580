import { createResource, createSignal, For, Match, on, onCleanup, onMount, Show, Switch } from "solid-js";

import socket from "./socket";
import ResultsTable from "./ResultsTable";
import AdminUsers from "./AdminUsers";
import { useAdminContext } from "./AdminContext";

export default function Admin() {

    const [adminStore, setAdminStore] = useAdminContext();
        

    const [activeTab, setActiveTab] = createSignal('Liste Etudiants');
    
    const tabClass = (tabName) => {
        return activeTab() === tabName ? 'nav-link active' : 'nav-link';
    }
    const handleTabClick = (tabName) => (event) => {
        setActiveTab(tabName);
        event.preventDefault();
    };

    return <>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a 
                    class={tabClass('Liste Etudiants')}
                    aria-current="page" href="#"
                    onClick={handleTabClick('Liste Etudiants')}>Liste Etudiants</a>
            </li>
            <li class="nav-item">
                <a class={tabClass('Tableaux des résultats')} href="#"
                    onClick={handleTabClick('Tableaux des résultats')}>Tableaux des résultats</a>
            </li>
            <li class="nav-item">
                <a class={tabClass('Liste des Utilisateurs')} href="#"
                    onClick={handleTabClick('Liste des Utilisateurs')}>Liste des Utilisateurs</a>
            </li>
        </ul>
        <Switch>
            <Show when={adminStore.users}>
                <Match when={activeTab() === 'Liste Etudiants'}>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Repo</th>
                    </tr>
                </thead>
                <tbody>
                <For each={adminStore.users} fallback={<div>Loading...</div>}>
                    {(item, index) => (
                        <tr>
                            <td>{index()+1}</td>
                            <td>
        {item.username} {item.role === 'admin' ? '👑' : ''}
                            </td>
                            <td>
        {item.project_id ? <a target="_blank" href={item.project_url}>{item.project_url}</a> : 'No project'}
                            </td>
                        </tr>
                    )}
                </For>
                </tbody>
            </table>
            </Match>
        </Show>
        <Match when={activeTab() === 'Tableaux des résultats'}>
            <div>
                <button 
                    type="button" 
                    className="btn btn btn-primary btn-sm" 
                    disabled={adminStore.resultsFetching} 
                    onClick={() => {
                        socket.emit('fetch-results');
                        setAdminStore('resultsFetching', true);
                    }}>Fetch Results</button>
            </div>
            <Show when={adminStore.results && adminStore.users && !adminStore.resultsFetching}>
                <ResultsTable />
            </Show>
            <Show when={adminStore.resultsFetching}>
                <div>Loading...</div>
            </Show>
        </Match>
        <Match when={activeTab() === 'Liste des Utilisateurs'}>
            <AdminUsers />
        </Match>
        </Switch>
    </>
}