
import { Match, Switch, on, onCleanup, Show, splitProps } from "solid-js";
import * as config from './config';

const Loader = () => <div class="spinner-border" role="status">Loading...</div>;

export const ProjectView = (props) => {


    console.log('projectView', props.state().project);
/*
    if(props.state().project.error) {
      switch(props.state().project.error){
        
        case 'NO_PROJECT':
          return <div class="alert alert-warning" role="alert">
            Le projet n'a pas encore été créé <button
            disabled={props.state().creatingProject}
            type="button"
            class="btn btn-primary"
            onClick={[props.createProject, null]}>
              {props.state().creatingProject ? ' Création du Projet' : 'Créer le projet'}
            </button>
          </div>
        case 'NO_GITLAB_USER':
          return <div class="alert alert-warning" role="alert">
            L'utilisateur gitlab nommé <b>{props.state().username}</b> n'existe pas,
            créez un compte sur <a href={config.GITLAB_URL}
            target='_blank'
            class="alert-link">{config.GITLAB_URL}</a> puis recommencez.
          </div>
        default:
        case 'UNKNOWN':
          return <div class="alert alert-danger" role="alert">
            Erreur Inconnue, recommencez ou contactez l'administrateur.
          </div>
      }
    }*/
    return <div>
    <Show when={props.state().project}>
        <Show when={props.state().project.error}>
            <Switch fallback={<div class="alert alert-danger" role="alert">
                Erreur Inconnue, recommencez ou contactez l'administrateur.<br/>
                ({props.state().project.message})
            </div>}>
                <Match when={props.state().project.error === 'NO_PROJECT'}>
                    <div class="alert alert-warning" role="alert">
                        Le projet n'a pas encore été créé <button
                        disabled={props.state().creatingProject}
                        type="button"
                        class="btn btn-primary"
                        onClick={[props.createProject, null]}>
                        {props.state().creatingProject ? ' Création du Projet' : 'Créer le projet'}
                        </button>
                    </div>
                </Match>
                <Match when={props.state().project.error === 'NO_GITLAB_USER'}>
                    <div class="alert alert-warning" role="alert">
                        L'utilisateur gitlab nommé <b>{props.state().username}</b> n'existe pas,
                        créez un compte sur <a href={config.GITLAB_URL}
                        target='_blank'
                        class="alert-link">{config.GITLAB_URL}</a> puis recommencez.
                    </div>
                </Match>
            </Switch>
        </Show>
        <Show when={!props.state().project.error}>
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">
                        Projet <a href={props.state().project.url}> {props.state().project.url}</a>
                    </h5>
                    <h6 class="card-subtitle mb-2 text-muted">id: {props.state().project.id}</h6>
                    <a href={props.state().project.url} class="card-link">Repository</a>
                    <a href={config.pagesUrl(props.state().project)} class="card-link">Pages</a>
                </div>
            </div>
        </Show>
    </Show>
    </div>
}



