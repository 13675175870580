import socketIO from "socket.io-client";
import { onMount, Show, createSignal } from "solid-js";

const socket = socketIO({
  transports: ["websocket", "polling"] // use WebSocket first, if available
});

// retrieve query params 'jwt'
const urlParams = new URLSearchParams(window.location.search);
const jwt = urlParams.get('jwt') || localStorage.getItem('jwt');
if(jwt) {
  localStorage.setItem('jwt', jwt);
}
if(urlParams.get('jwt')) {
  //remove jwt from url
  window.history.replaceState({}, document.title, "/");
}

socket.on("connect", () => {
  console.log("connected");
  console.log({jwt});
  if(jwt) {
    socket.emit("authenticate", jwt);
  }
});

socket.on("unauthorized", (error) => {
  console.log({error});
  localStorage.removeItem('jwt');
});

export default socket;


export const SocketStatus = () => {
  const [status, setStatus] = createSignal('disconnected');

  onMount(() => {
    socket.on('connect', () => {
      console.log('connected');
      setStatus('connected');
    });
    socket.on('disconnect', () => {
      setStatus('disconnected');
      console.log('disconnect');
    });
  });

  return <Show when={status() == 'disconnected'}><div class="socket-status">
    <div class="alert alert-danger" role="alert">

      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
          Connexion au serveur perdue...
    </div>
    </div>
  </Show>
}