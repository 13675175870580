import { render } from 'solid-js/web';
import App from './App';

const app = document.getElementById('app');


if (app) {
  render(() => <div>
  <div class="container">
  <App />
  </div>
  <footer class="footer" style={{display:'none'}}>
    <div class="container">
      <span class="text-muted"><a href="http://bramas.fr">bramas.fr</a></span>{' | '}
      <span class="text-muted"><a href="#" onclick={() => {}}>Se déconnecter</a></span>
    </div>
  </footer>
</div>, app);
}